import React, {useState} from "react"
import "./styles.css"

interface PopupProps {
    mediaUrl: string
    onClose: () => void
}

const Popup: React.FC<PopupProps> = ({mediaUrl, onClose}) => {
    const isImage = /\.(jpg|jpeg|png|gif)$/i.test(mediaUrl)
    const isVideo = /\.(mp4|webm|ogg|quicktime)$/i.test(mediaUrl)

    return (
        <div className="popup-overlay">
            <div className="popup-content">
                {isImage ? (
                    <img src={mediaUrl} alt="Media Content" />
                ) : isVideo ? (
                    <video controls>
                        <source src={mediaUrl} />
                        Your browser does not support the video tag.
                    </video>
                ) : (
                    <p>Unsupported media format</p>
                )}
                <div className="close-div">
                    <button onClick={onClose} className="close-btn">
                        &times;
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Popup
