import * as React from "react"
import {useEffect, useMemo, useState} from "react"
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined"
import FactCheckIcon from "@mui/icons-material/FactCheck"
import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined"
import AddOutlinedIcon from "@mui/icons-material/AddOutlined"
import PropTypes from "prop-types"
import Button from "@mui/material/Button"
import {styled} from "@mui/material/styles"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import "./styles.css"
import {useNavigate} from "react-router-dom"
import {selectCurrentUser} from "redux/features/appStateSlice"
import {useAppDispatch, useTypedSelector} from "redux/store"
import {ProjectState, Projects} from "types/Projects"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import {useForm} from "react-hook-form"
import {Error} from "components/auth/Error"
import {handleError} from "helpers"
import {Input} from "components/auth/Input"
import {getProjectsFromStore, selectProjects, setCurrentProjectId} from "redux/features/projectSlice"
import CircularProgress from "@mui/material/CircularProgress"
import {projectService} from "services"
import {toastError, toastSuccess} from "helpers/toastHelper"
import DeleteConfirmation from "components/deleteConfirmation"
import Tooltip from "@mui/material/Tooltip"
import {resetChatbot, selectQuotaDetails} from "redux/features/chatbotSlice"
import {useIsStaff} from "hooks/useAuth"

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2)
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1)
    }
}))

function BootstrapDialogTitle(props: any) {
    const {children, onClose, ...other} = props

    return (
        <DialogTitle sx={{m: 0, p: 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
}

const inputsConfig = {
    name: {
        name: "title",
        placeholder: "Enter Project Name",
        className: "form-control",
        type: "text",
        required: "Enter Project name"
    },
    description: {
        name: "shortDescription",
        placeholder: "Enter Project Description",
        className: "form-control",
        type: "text"
        // required: "Enter Project Description",
    }
}

type Props = {}

const initialState: ProjectState = {
    open: false,
    isEdit: false,
    isProjectDeleting: false,
    isLoading: false,
    projectMsg: "You will loss all chatbots which are created in project!"
}

let projectDetail: any

const Project = (props: Props) => {
    const [errorMessage, setErrorMessage] = useState("")
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const openDropdown = Boolean(anchorEl)
    const navigate = useNavigate()
    const [{open, isEdit, isProjectDeleting, projectMsg, isLoading}, setState] = useState(initialState)
    const [isAlertOpen, setIsAlertOpen] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const dispatch = useAppDispatch()
    const user = useTypedSelector(selectCurrentUser)
    const projects: Array<Projects> = useTypedSelector(selectProjects)
    const quotaDetails = useTypedSelector(selectQuotaDetails)
    const isStaffAccess = useIsStaff()
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: {errors},
        clearErrors
    } = useForm()

    const goToChatbotPage = (clientProjectId) => {
        dispatch(resetChatbot())
        dispatch(setCurrentProjectId(clientProjectId))
        navigate("/chatbots")
    }

    const dropdownHandleClick = (event: React.MouseEvent<HTMLElement>, listValue) => {
        event.stopPropagation()
        projectDetail = listValue
        setAnchorEl(event.currentTarget)
    }

    const dropdownHandleEdit = (event, value) => {
        event.stopPropagation()
        setAnchorEl(null)
        setState((prev) => ({...prev, open: true, isEdit: value}))
        setValue("title", projectDetail.title)
        setValue("shortDescription", projectDetail.shortDescription)
        clearErrors()
    }

    const dropdownHandleDelete = (event) => {
        event.stopPropagation()
        setAnchorEl(null)
        setIsAlertOpen(true)
    }

    const onSubmit = async ({title, shortDescription}) => {
        try {
            setIsSubmitting(true)
            if (!isEdit) {
                const response = await projectService.createProject({
                    title,
                    shortDescription
                })

                if (response?.data?.success) {
                    setState((prev) => ({...prev, open: false}))
                    toastSuccess(response?.data?.message)
                }
            } else {
                const response = await projectService.updateProject({
                    clientProjectId: projectDetail.clientProjectId,
                    title,
                    shortDescription
                })

                if (response?.data?.success) {
                    setState((prev) => ({...prev, open: false}))
                    toastSuccess(response?.data?.message)
                }
            }

            getProjects()
        } catch (e) {
        } finally {
            setIsSubmitting(false)
        }
    }

    useEffect(() => {
        getProjects()
    }, [])

    const getProjects = async () => {
        dispatch(getProjectsFromStore({}))
    }

    useEffect(() => {
        setState((prevState) => ({
            ...prevState,
            isLoading: projects["isLoading"]
        }))
    }, [projects["isLoading"]])

    const handleClickOpen = (event, value) => {
        event.stopPropagation()
        setState((prev) => ({...prev, open: true, isEdit: value}))
        setValue("title", "")
        setValue("shortDescription", "")
        clearErrors()
    }

    const handleClose = (e) => {
        e.stopPropagation()
        setAnchorEl(null)
        setState((prev) => ({
            ...prev,
            open: false
        }))
    }

    const refreshChatBot = () => {}

    const handleDelete = async () => {
        try {
            projectLodingHandle()
            const response = await projectService.deleteProject({
                clientProjectId: [projectDetail.clientProjectId]
            })

            if (response?.data?.success) {
                projectLodingHandle()
                toastSuccess(response?.data?.message)
                getProjects()
                refreshChatBot()
                setIsAlertOpen(false)
            }
        } catch (e) {
            projectLodingHandle()
            toastError(e["response"]?.data?.message)
        }
    }

    const projectLodingHandle = (isTrue = false) => {
        setState((prevState) => ({
            ...prevState,
            isProjectDeleting: isTrue
        }))
    }

    const onChange =
        (name: any) =>
        ({target}: any) => {
            setValue(name, target.value)
            setErrorMessage("")
        }

    const renderInput = (inputProps = {}) => {
        return (
            <Input
                {...{
                    register,
                    errors,
                    watch,
                    onChange,
                    ...inputProps
                }}
            />
        )
    }

    return (
        <>
            <div
                className="project_lists"
                style={{
                    marginTop: `${quotaDetails?.data?.isQuestionQuotaToBeExpired && "2rem"}`
                }}>
                <div className="container-fluid">
                    <h4 className="my-4">Project List</h4>

                    {!isLoading ? (
                        <div className="row">
                            {projects["projects"]?.map((project: Projects, index: any) => {
                                return (
                                    <div
                                        key={project?.clientProjectId + "_" + index}
                                        className="col-lg-3 pb-4 card-pointer"
                                        onClick={() => goToChatbotPage(project.clientProjectId)}>
                                        <Tooltip title={project?.title} placement="top">
                                            <div className="__inner">
                                                <div className="name">
                                                    <span className="material-symbols-outlined">app_registration</span>
                                                    <h2 className="textElipses mx-2">{project?.title || ""}</h2>
                                                    {!isStaffAccess && (
                                                        <span
                                                            id={index}
                                                            aria-controls={openDropdown ? index : undefined}
                                                            aria-haspopup="true"
                                                            aria-expanded={openDropdown ? "true" : undefined}
                                                            onClick={(e: any) => dropdownHandleClick(e, project)}
                                                            className="material-symbols-outlined
                        ">
                                                            more_vert
                                                        </span>
                                                    )}
                                                    <Menu
                                                        id={index}
                                                        aria-labelledby={index}
                                                        anchorEl={anchorEl}
                                                        open={openDropdown}
                                                        onClose={handleClose}
                                                        anchorOrigin={{
                                                            vertical: "top",
                                                            horizontal: "left"
                                                        }}
                                                        transformOrigin={{
                                                            vertical: "top",
                                                            horizontal: "left"
                                                        }}>
                                                        <MenuItem onClick={(e: any) => dropdownHandleEdit(e, true)}>
                                                            Edit
                                                        </MenuItem>
                                                        <MenuItem onClick={(e: any) => dropdownHandleDelete(e)}>
                                                            Delete
                                                        </MenuItem>
                                                    </Menu>
                                                </div>
                                            </div>
                                        </Tooltip>
                                        <div className="__notification-area">
                                            <div className="icons">
                                                <span className="material-symbols-outlined">smart_toy</span>
                                                <span className="counts">{project?.botCount ?? 0}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            {!isStaffAccess && projects !== undefined && projects["isLoading"] == false && (
                                <div className="col-lg-3 pb-4">
                                    <div
                                        style={{height: "120px"}}
                                        className="empty_inner"
                                        onClick={(e) => handleClickOpen(e, false)}>
                                        <span>
                                            <AddOutlinedIcon fontSize="inherit" /> Create Project
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="progressBarDisplay">
                            <CircularProgress color="inherit" size={"1.5rem"} />
                        </div>
                    )}
                </div>
            </div>

            <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <BootstrapDialogTitle onClose={handleClose}>
                    {isEdit ? "Update Project Details" : "Add New Project"}
                </BootstrapDialogTitle>

                <DialogContent dividers>
                    <form onSubmit={handleSubmit(onSubmit)} className="add-project">
                        <div className="row">
                            <div className="form-group mb-4 col-lg-12">
                                <label htmlFor="">Project Name</label>
                                {renderInput(inputsConfig.name)}
                                {!!errors.title && <Error message={errors.title.message} />}
                            </div>
                            <div className="form-group col-lg-12">
                                <label htmlFor="">Project Description</label>
                                {renderInput(inputsConfig.description)}
                                {/* {!!errors.shortDescription && (
                    <Error message={errors.shortDescription.message} />
                  )} */}
                            </div>
                        </div>

                        <DialogActions>
                            <Button
                                style={{marginTop: "20px"}}
                                autoFocus
                                className="save-btn mx-auto"
                                type="submit"
                                disabled={isSubmitting}>
                                {isSubmitting ? <CircularProgress color="inherit" size={"1.5rem"} /> : "Save changes"}
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </BootstrapDialog>

            {isAlertOpen && (
                <DeleteConfirmation
                    onCancel={() => setIsAlertOpen(false)}
                    isOpen={isAlertOpen}
                    onAccept={handleDelete}
                    onLoading={isProjectDeleting}
                    projectMsg={projectMsg}
                />
            )}
        </>
    )
}

export default Project
