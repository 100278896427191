import ApiUrls from "./ApiUrl"
import BaseService from "./Base"

export default class SupportTicketService extends BaseService {
    async getTicketList(param = {}) {
        try {
            const response = await this.post(ApiUrls.supportTicketList, param)
            return response?.data
        } catch (error) {
            throw error
        }
    }
    async createNewTicket(param = {}) {
        try {
            const response = await this.post(ApiUrls.supportTicketCreate, param)
            return response?.data
        } catch (error) {
            throw error
        }
    }
}
