import DeleteIcon from "@mui/icons-material/Delete"
import PhotoCamera from "@mui/icons-material/PhotoCamera"
import {Box, Button, Grid, IconButton} from "@mui/material"
import {toastError} from "helpers/toastHelper"
const DEFAULT_IMAGE_TYPES = ["image/jpeg", "image/png", "image/jpg", "image/bmp", "image/webp", "image/tiff"]
const DEFAULT_VIDEO_TYPES = ["video/mp4", "video/webm", "video/ogg", "video/quicktime"]

const UploadMultiplePhotos = ({files, setFiles}) => {
    const handleFileChange = (event) => {
        const selectedFiles = Array.from(event.target.files)
        const filteredFiles = selectedFiles.filter((file: any) =>
            [...DEFAULT_IMAGE_TYPES, ...DEFAULT_VIDEO_TYPES].includes(file.type)
        )
        if (selectedFiles.length !== filteredFiles.length) {
            toastError("File not supported")
        }
        setFiles((prevFiles) => [...prevFiles, ...filteredFiles])
    }

    const handleRemoveFile = (fileToRemove) => {
        setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove))
    }

    return (
        <Box>
            <input
                accept={`${[...DEFAULT_IMAGE_TYPES, ...DEFAULT_VIDEO_TYPES].join(",")}`}
                style={{display: "none"}}
                id="upload-multiple-photos"
                multiple
                type="file"
                onChange={handleFileChange}
            />
            <label
                htmlFor="upload-multiple-photos"
                style={{width: "fit-content", borderRadius: "8px", border: ".5px solid #fd8212"}}>
                <Button variant="contained" component="span" startIcon={<PhotoCamera />}>
                    Upload Media
                </Button>
            </label>

            <Grid container spacing={2} mt={2}>
                {files.map((file: File, index) => (
                    <Grid item xs={4} key={index}>
                        <Box
                            sx={{
                                position: "relative",
                                border: "1px solid #ddd",
                                borderRadius: "8px",
                                overflow: "hidden"
                            }}>
                            {DEFAULT_IMAGE_TYPES.includes(file.type) && (
                                <img
                                    src={URL.createObjectURL(file)}
                                    alt="preview"
                                    style={{width: "100%", height: "auto"}}
                                />
                            )}
                            {DEFAULT_VIDEO_TYPES.includes(file.type) && (
                                <video src={URL.createObjectURL(file)} width="100%" height="100%" controls />
                            )}
                            <IconButton
                                sx={{position: "absolute", top: 0, right: 0}}
                                onClick={() => handleRemoveFile(file)}>
                                <DeleteIcon color="error" />
                            </IconButton>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}

export default UploadMultiplePhotos
