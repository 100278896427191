import {IconButton, Stack} from "@mui/material"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"
import {DataGrid, GridColDef} from "@mui/x-data-grid"
import {useAuth} from "hooks/useAuth"
import {useEffect, useState} from "react"
import {supportTicketService} from "services"
import {Ticket} from "types/HelpAndSupport"
import CreateTicketModal from "./parts/CreateTicketModal"
import ViewTicketModal from "./parts/ViewTicketModal"
import "./styles.css"
import {Loader} from "components"

const HelpSupport = () => {
    const [ticketList, setTicketList] = useState<Ticket[]>([])
    const [isListLoading, setIsListLoading] = useState(false)
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(20)
    const [total, setTotal] = useState(0)
    const [isOpen, setIsOpen] = useState(false)
    const [selectedTicket, setSelectedTicket] = useState<Ticket | null>(null)
    const auth = useAuth()

    useEffect(() => {
        setIsListLoading(true)
        if (auth.user) {
            getTicketList()
        }
    }, [auth])

    const columns: GridColDef[] = [
        {
            field: "customTicketId",
            headerName: "Ticket Number",
            cellClassName: "capitalize",
            flex: 0.7
        },
        {
            field: "subject",
            headerName: "Subject",
            flex: 1
        },
        {
            field: "description",
            headerName: "Description",
            flex: 1,
            cellClassName: "capitalize"
        },
        {
            field: "action",
            headerName: "Action",
            minWidth: 300,
            flex: 1,
            renderCell: (cellValues) => {
                return (
                    <Stack direction="row" spacing={1}>
                        <IconButton
                            aria-label="view"
                            onClick={(e: any) => {
                                setSelectedTicket(cellValues.row)
                            }}>
                            <span className="material-symbols-outlined ">
                                <RemoveRedEyeIcon />
                            </span>
                        </IconButton>
                    </Stack>
                )
            }
        }
    ]

    const onClose = () => {
        setIsOpen(false)
    }

    const getTicketList = async (_page = page, _pageSize = pageSize) => {
        const {data, total} = await supportTicketService.getTicketList({
            range: {
                page: _page,
                pageSize: _pageSize
            }
        })
        setTicketList(data)
        setTotal(total)
        setIsListLoading(false)
    }

    const onModelChange = (event) => {
        setPage(event.page + 1)
        setPageSize(event.pageSize)
        getTicketList(event.page + 1, event.pageSize)
    }

    return (
        <>
            <div className="project_lists">
                <div className="container-fluid">
                    <div className="d-flex justify-content-between align-items-center">
                        <h4 className="my-4 mx-2">Help and support</h4>
                        {auth?.user?.roleId !== 1 && (
                            <button onClick={() => setIsOpen((prev) => !prev)} className="send-button">
                                Add New Ticket
                            </button>
                        )}
                    </div>
                    <div className="mt-2 p-3 content-form-card">
                        <div className="staffTable">
                            <DataGrid
                                style={{border: 0}}
                                pagination
                                paginationMode="server"
                                rows={ticketList}
                                columns={columns}
                                getRowId={(row) => `${row.socketId}_${Math.floor(Math.random() * 100000000)}`}
                                loading={isListLoading}
                                onPaginationModelChange={onModelChange}
                                rowCount={Number(total) || 0}
                                initialState={{
                                    pagination: {
                                        paginationModel: {page, pageSize}
                                    }
                                }}
                                pageSizeOptions={[10, 20, 30, 40, 50]}
                                localeText={{noRowsLabel: "No Ticket Found"}}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {isOpen && (
                <CreateTicketModal
                    onClose={onClose}
                    isOpen={isOpen}
                    getTicketList={getTicketList}
                    setisLoader={setIsListLoading}
                />
            )}
            {selectedTicket && (
                <ViewTicketModal
                    isOpen={selectedTicket !== null}
                    onClose={() => {
                        setSelectedTicket(null)
                    }}
                    ticketData={selectedTicket}
                />
            )}
        </>
    )
}

export default HelpSupport
