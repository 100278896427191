import CloseIcon from "@mui/icons-material/Close"
import {Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography} from "@mui/material"
import React, {useState} from "react"
import {Ticket} from "types/HelpAndSupport"
import Popup from "./imgPopup/Popup"

interface ViewTicketModalProps {
    onClose: () => void
    isOpen: boolean
    ticketData: Ticket
}
interface popup {
    status: boolean
    id: number | null
}
const ViewTicketModal = React.memo(({onClose, isOpen, ticketData}: ViewTicketModalProps) => {
    const [isPopupOpen, setPopupOpen] = useState<popup>({status: false, id: null})
    const openPopup = (id: number) =>
        setPopupOpen({
            status: true,
            id: id
        })
    const closePopup = () =>
        setPopupOpen({
            status: false,
            id: null
        })
    const {subject, description, medialibrary: mediaLibrary, customTicketId} = ticketData

    const DEFAULT_VIDEO_TYPES = ["MP4", "WEBM", "OGG", "QUICKTIME"]

    return (
        <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={isOpen} maxWidth="md" fullWidth>
            <DialogTitle>
                Ticket No : {customTicketId}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Typography variant="h6" gutterBottom color="textSecondary">
                    Subject:
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    {subject}
                </Typography>
                <div
                    style={{
                        marginTop: "25px"
                    }}>
                    <Typography variant="h6" gutterBottom color="textSecondary">
                        Description:
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {description}
                    </Typography>
                </div>

                {mediaLibrary.length !== 0 && (
                    <div style={{marginTop: "25px"}}>
                        <Typography variant="h6" gutterBottom color="textSecondary">
                            Supported Files: <span style={{fontWeight: "normal"}}>(Click to open)</span>
                        </Typography>

                        <Stack spacing={3} sx={{alignItems: "center", marginTop: 2}}>
                            <div
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(3, 1fr)",
                                    gap: "1"
                                }}>
                                {mediaLibrary.map((mediaLibrary, index) => (
                                    <div>
                                        {DEFAULT_VIDEO_TYPES.includes(
                                            mediaLibrary.split(".")[mediaLibrary.split(".").length - 1].toUpperCase()
                                        ) ? (
                                            <video
                                                key={index}
                                                src={mediaLibrary ?? ""}
                                                onClick={() => {
                                                    openPopup(index)
                                                }}
                                                style={{
                                                    width: "80%",
                                                    cursor: "pointer",
                                                    borderRadius: "10px",
                                                    boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.1)",
                                                    transition: "transform 0.3s ease",
                                                    marginBottom: "15px"
                                                }}
                                                onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.02)")}
                                                onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                                            />
                                        ) : (
                                            <img
                                                key={index}
                                                src={mediaLibrary ?? ""}
                                                alt={`Screenshot ${index + 1}`}
                                                onClick={() => openPopup(index)}
                                                style={{
                                                    width: "80%",
                                                    cursor: "pointer",
                                                    borderRadius: "10px",
                                                    boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.1)",
                                                    transition: "transform 0.3s ease",
                                                    marginBottom: "15px"
                                                }}
                                                onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.02)")}
                                                onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                                            />
                                        )}
                                    </div>
                                ))}
                                {isPopupOpen.status && (
                                    <Popup mediaUrl={mediaLibrary[isPopupOpen.id]} onClose={closePopup} />
                                )}
                            </div>
                        </Stack>
                    </div>
                )}
            </DialogContent>
        </Dialog>
    )
})

export default ViewTicketModal
