import ApiUrls from "./ApiUrl"
import BaseService from "./Base"

export default class UploadFileService extends BaseService {
    async uploadFile(params = {}) {
        try {
            const response = await this.post(`${ApiUrls.uploadFile}/file`, params)
            return response
        } catch (error) {
            throw error
        }
    }
    async uploadMultipleFile(params = {}) {
        try {
            const response = await this.post(`${ApiUrls.uploadFile}/multiple-file`, params)
            return response
        } catch (error) {
            throw error
        }
    }
}
