import CloseIcon from "@mui/icons-material/Close"
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, styled} from "@mui/material"
import {Error} from "components/auth/Error"
import {toastError, toastSuccess} from "helpers/toastHelper"
import React, {useState} from "react"
import {useForm} from "react-hook-form"
import {supportTicketService, uploadFileService} from "services"
import UploadMultiplePhotos from "./UploadMultipleFiles"

interface CreateTicketModalProps {
    onClose: () => void
    isOpen: boolean
    getTicketList: () => void
    setisLoader: (val: boolean) => void
}
const CreateTicketModal = React.memo(({onClose, isOpen, getTicketList, setisLoader}: CreateTicketModalProps) => {
    const [mediaLibrary, setMediaLibrary] = useState<string[]>([])
    const {
        register,
        handleSubmit,
        formState: {errors}
    } = useForm()
    const onSubmit = async ({subject, description}) => {
        onClose()
        setisLoader(true)
        let payload: {
            subject: string
            description: string
            mediaLibrary?: string[]
        } = {
            subject,
            description
        }
        if (mediaLibrary.length) {
            const formData = new FormData()
            mediaLibrary.forEach((file) => {
                formData.append("file", file)
            })
            const {data} = await uploadFileService.uploadMultipleFile(formData)
            payload = {
                ...payload,
                mediaLibrary: data.uploadPaths
            }
        }

        try {
            const {message} = await supportTicketService.createNewTicket(payload)
            toastSuccess(message)
        } catch (error: any) {
            toastError(error)
            setisLoader(false)
        }
        getTicketList()
        setisLoader(false)
    }

    return (
        <BootstrapDialog onClose={onClose} aria-labelledby="customized-dialog-title" open={isOpen}>
            <BootstrapDialogTitle onClose={onClose}>Add New Ticket</BootstrapDialogTitle>
            <DialogContent dividers>
                <form onSubmit={handleSubmit(onSubmit)} className="add-project">
                    <div className="row">
                        <div className="form-group mb-4 col-lg-12">
                            <label htmlFor="">Subject</label>
                            <input
                                type="text"
                                placeholder="Enter Subject"
                                className="roleInputForm"
                                {...register("subject", {
                                    required: "Enter Subject"
                                })}
                            />
                            {!!errors.subject && <Error message={errors.subject.message} />}
                        </div>
                        <div className="form-group mb-4 col-lg-12">
                            <label htmlFor="">Description</label>
                            <textarea
                                placeholder="Enter Description"
                                className="roleInputForm"
                                {...register("description", {
                                    required: "Enter Description"
                                })}
                                rows={8}
                            />
                            {!!errors.description && <Error message={errors.description.message} />}
                        </div>
                        <UploadMultiplePhotos files={mediaLibrary} setFiles={setMediaLibrary} />
                    </div>
                    <DialogActions>
                        <Button className="save-btn mx-auto" type="submit">
                            Submit
                        </Button>
                    </DialogActions>
                </form>
            </DialogContent>
        </BootstrapDialog>
    )
})
const BootstrapDialog = styled(Dialog)(({theme}) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2)
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1)
    }
}))

type BootstrapDialogTitleProps = {
    children: React.ReactNode
    onClose?: () => void
}

function BootstrapDialogTitle(props: BootstrapDialogTitleProps) {
    const {children, onClose, ...other} = props
    return (
        <DialogTitle sx={{m: 0, p: 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

export default CreateTicketModal
