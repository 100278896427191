import React, {useState, useEffect} from "react"
import {inboxService, clientDashboardService, chatbotService} from "services"
import Box from "@mui/material/Box"
import {getFormatedDateWithTime} from "helpers/common"
import "./styles.css"
import {Chart as ChartJS, CategoryScale, LinearScale, ArcElement, BarElement, Title, Tooltip, Legend} from "chart.js"
import {Bar, Doughnut} from "react-chartjs-2"
import "./styles.css"
import {CircularProgress} from "@mui/material"
import {useTypedSelector} from "redux/store"
import {selectQuotaDetails} from "redux/features/chatbotSlice"
type Props = {}

ChartJS.register(CategoryScale, LinearScale, ArcElement, BarElement, Title, Tooltip, Legend)

export const options = {
    title: {
        display: true
    },
    legend: {
        display: false
    },
    responsive: true, // Instruct chart js to respond nicely.
    // Add to prevent default behaviour of full-width/height
    plugins: {
        tooltip: {
            bodyFont: {
                size: 14,
                family: "Montserrat"
            }
        }
    },
    scales: {
        x: {
            display: true,
            text: "Duration",
            ticks: {
                font: {
                    size: 14,
                    family: "Montserrat"
                }
            }
        },
        y: {
            display: true,
            text: "Leads Count",
            ticks: {
                precision: 0,
                font: {
                    size: 14,
                    family: "Montserrat"
                }
            }
        }
    }
}

export const doughnutOptions = {
    responsive: true,
    plugins: {
        tooltip: {
            bodyFont: {
                size: 14,
                family: "Montserrat"
            }
        }
    }
}

interface state {
    reportListCount: any
    leadGraphData: any
    questionTokenData: any
    leadList: any[]
    leadShowingFilter: string
    isCountLoading: boolean
}

const initialState: state = {
    reportListCount: {},
    leadGraphData: {
        labels: [],
        datasets: [
            {
                label: "Leads Count",
                data: [],
                backgroundColor: "#9ba8f4",
                trapped: "#9ba8f4"
            }
        ]
    },
    questionTokenData: {
        labels: [],
        datasets: [
            {
                label: ["", ""],
                data: [],
                backgroundColor: ["#fd8212", "#9ba8f4"]
            }
        ]
    },
    leadList: [],
    leadShowingFilter: "week",
    isCountLoading: false
}

const ClientDashboard = (props: Props) => {
    const [{leadList, leadGraphData, questionTokenData, reportListCount, isCountLoading}, setState] =
        useState(initialState)

    const quotaDetails = useTypedSelector(selectQuotaDetails)

    useEffect(() => {
        getReportList()
        getLeadList()
        getLeadFilterList()
        getQuestionAndTokenData()
    }, [])

    const handleCountLoading = (isCountLoading = false) => {
        setState((prevState) => ({
            ...prevState,
            isCountLoading: isCountLoading
        }))
    }

    const getReportList = async () => {
        try {
            handleCountLoading(true)
            let payload = {}
            const response = await clientDashboardService.getReportList(payload)
            if (response?.success) {
                setState((prevState) => ({
                    ...prevState,
                    reportListCount: response.data
                }))
                handleCountLoading()
            }
        } catch (error) {
            handleCountLoading()
        }
    }

    const leadBarGraphData = (labels, data) => {
        setState((prevState) => ({
            ...prevState,
            leadGraphData: {
                labels: labels,
                datasets: [
                    {
                        label: "Leads Count",
                        data: data,
                        backgroundColor: "#9ba8f4"
                    }
                ]
            }
        }))
    }

    const questionTokenGraphData = (labels, data) => {
        setState((prevState) => ({
            ...prevState,
            questionTokenData: {
                labels: labels,
                datasets: [
                    {
                        label: [],
                        data: data,
                        backgroundColor: ["#fd8212", "#9ba8f4"]
                    }
                ]
            }
        }))
    }

    const getLeadFilterList = async (dateRangeFilter = "week") => {
        try {
            let payload = {
                filter: {
                    dateRangeType: dateRangeFilter
                }
            }
            const response = await clientDashboardService.getLeadGraphListByFilter(payload)
            if (response?.success) {
                let labels = response?.data?.labels
                let data = response?.data?.values
                leadBarGraphData(labels, data)
            }
        } catch (error) {}
    }

    const getQuestionAndTokenData = async () => {
        try {
            let payload = {}
            const response = await chatbotService.getQuotaDetails(payload)
            if (response?.success) {
                let labels = ["Question Balance", "Asked Question"]
                let data = [response?.data?.questionBalance, response?.data?.askedQuestion]
                questionTokenGraphData(labels, data)
            }
        } catch (error) {}
    }

    const getLeadList = async () => {
        try {
            let payload = {
                filter: {},
                range: {
                    page: 1,
                    pageSize: 10
                }
            }
            const response = await inboxService.leadList(payload)
            if (response?.data?.success) {
                setState((prevState) => ({
                    ...prevState,
                    isListLoading: false
                }))
                let data = response.data.data.map((item: any) => ({
                    ...item,
                    createdAt: getFormatedDateWithTime(item.createdAt)
                }))
                setState((prevState) => ({
                    ...prevState,
                    leadList: data
                }))
            }
        } catch (error) {}
    }

    const filterLeadDuration = (event: any) => {
        event.preventDefault()
        let {value} = event.target
        setState((prevState) => ({
            ...prevState,
            leadShowingFilter: value
        }))
        getLeadFilterList(value)
    }

    return (
        <div
            className="dashboard_inner"
            style={{
                marginTop: `${quotaDetails?.data?.isQuestionQuotaToBeExpired && "4rem"}`
            }}>
            <h4 className="my-4 ml-5">Dashboard</h4>
            <div className="top">
                <Box
                    sx={{
                        m: 1
                    }}>
                    <div className="widgetDisplay">
                        <div>
                            <h4>Total Questions:</h4>
                        </div>
                        <div>
                            {!isCountLoading ? (
                                <h1>{reportListCount?.totalQuestionsAnswered || 0}</h1>
                            ) : (
                                <CircularProgress color="inherit" size={"1.5rem"} />
                            )}
                        </div>
                    </div>
                </Box>
                <Box
                    sx={{
                        m: 1
                    }}>
                    <div className="widgetDisplay">
                        <div>
                            <h4>Total Lead Captured:</h4>
                        </div>
                        <div>
                            {!isCountLoading ? (
                                <h1>{reportListCount?.totalLeadsCaptured || 0}</h1>
                            ) : (
                                <CircularProgress color="inherit" size={"1.5rem"} />
                            )}
                        </div>
                    </div>
                </Box>
                <Box
                    sx={{
                        m: 1
                    }}>
                    <div className="widgetDisplay">
                        <div>
                            <h4>Active Chatbots:</h4>
                        </div>
                        <div>
                            {!isCountLoading ? (
                                <h1>{reportListCount?.activeChatbotCount || 0}</h1>
                            ) : (
                                <CircularProgress color="inherit" size={"1.5rem"} />
                            )}
                        </div>
                    </div>
                </Box>
            </div>

            <div className="middle">
                <Box
                    sx={{
                        boxShadow: 0.5,
                        p: 4,
                        m: 1,
                        borderRadius: 2,
                        backgroundColor: "white"
                    }}>
                    <h5 className="list_title">Leads Data</h5>
                    <Bar data={leadGraphData} width={100} height={30} options={options} />
                    <hr />
                    <div className="row alignment">
                        <div className="col-2 textCenter">
                            <h6>Data Showing For:</h6>
                        </div>
                        <div className="col-2">
                            <select className="form-select" onChange={filterLeadDuration}>
                                <option value="Week">Week</option>
                                <option value="Month">Month</option>
                                <option value="Today">Today</option>
                            </select>
                        </div>
                    </div>
                </Box>
            </div>

            <div className="row">
                <div className="col-sm-12 col-md-6">
                    <Box
                        sx={{
                            boxShadow: 0.5,
                            p: 2,
                            m: 1,
                            borderRadius: 2,
                            backgroundColor: "white",
                            height: "625px"
                        }}>
                        <h5 className="list_title">Latest 10 Leads</h5>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Lead Name</th>
                                    <th>Lead Created On</th>
                                    {/* <th>Lead Status</th> */}
                                    <th>Callback Required</th>
                                </tr>
                            </thead>
                            <tbody>
                                {leadList.length > 0 &&
                                    leadList?.map((user) => (
                                        <tr key={user.socketId}>
                                            <td className="capitalize">{user.userDisplayName}</td>
                                            <td>{user.createdAt}</td>
                                            {/* <td className="capitalize">{user.leadStatus}</td> */}
                                            <td>{user.isCallbackRequired ? "Yes" : "No"}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </Box>
                </div>

                <div className="col-sm-12 col-md-6">
                    <Box
                        sx={{
                            p: 4,
                            m: 1,
                            boxShadow: 0.5,
                            borderRadius: 2,
                            backgroundColor: "white"
                        }}>
                        <h5 className="list_title text-center">Question Balance vs Asked Question</h5>
                        {<Doughnut data={questionTokenData} width={100} height={0} options={doughnutOptions} />}
                    </Box>
                </div>
            </div>
        </div>
    )
}

export default ClientDashboard
